<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      @update:show="limpiarDatos()"
      :closeOnBackdrop="false"
      :show.sync="ActualizaEmpresa"
    >
      <CustomTabs :active-tab="0" class="text-table">
        <CustomTab :title="$t('label.basicData')" icon="general">
          <CRow class="mt-2">
            <CCol sm="12" md="9" lg="5" >                
                  <div class="preview">    
                    <picture-input
                        ref="imageInput"
                        :imgSrc="image"
                        width="200"
                        height="200"
                        accept="image/jpeg,image/png,image"
                        size="10"
                        :custom-strings="imgInputTexts"
                        @change="handleFileUpload"
                        v-if="ActualizaEmpresa"
                    >
                    </picture-input>
                </div>
            </CCol>
            <CCol sm="12"  lg="7">
              <CRow class="mr-2">
                <CCol sm="12" lg="8" class="pl-0"><!-- rif -->
                  <CSelect                                                                                                                                                                       
                    v-model="LetraRif"                        
                    :value.sync="LetraRif"
                    :options="LetraOptions"
                    addLabelClasses="text-right required"
                    :horizontal="{label:'col-sm-12 col-lg-8', input:'col-sm-12 col-lg-4'}"
                    :label="$t('label.rifNumber')"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" lg="4" class="pl-0"> <!-- rif -->
                  <CInput
                    :horizontal="{input:'col-sm-12 col-lg-12'}"
                    v-uppercase                          
                    ref="RifCliente"
                    maxlength="9"
                    size="sm"
                    :is-valid="hasError($v.CompanyRif)"
                    v-model.trim="$v.CompanyRif.$model"
                    :invalid-feedback="errorMessage($v.CompanyRif)"
                  />
                </CCol>
                <CCol sm="12" lg="12">                  
                  <CInput
                    :label="$t('label.company')"         
                    v-uppercase
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    addLabelClasses="text-right required"
                    maxlength="500"
                    size="sm"
                    v-model.trim="$v.CompanyName.$model"
                    :is-valid="hasError($v.CompanyName)"
                    :invalid-feedback="errorMessage($v.CompanyRif)"
                  />
                </CCol>
                <CCol sm="12" lg="12">                    
                  <CTextarea                    
                    :label="$t('label.object')"
                    addLabelClasses="text-right required"
                    v-model="CompanyObject"
                    placeholder=""
                    size="sm"                      
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    v-model.trim="$v.CompanyObject.$model"
                    :is-valid="hasError($v.CompanyObject)"
                    :invalid-feedback="errorMessage($v.CompanyObject)"
                  />
                </CCol>                                  
                <CCol sm="12" lg="12">              
                  <CInput
                    :label="$t('label.mainPhone')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    v-uppercase
                    v-model="PrimaryPhone"
                    maxlength="15"
                    size="sm"
                    v-model.trim="$v.PrimaryPhone.$model"
                    :is-valid="hasError($v.PrimaryPhone)"
                    :invalid-feedback="errorMessage($v.PrimaryPhone)"
                  />
                </CCol>
                <CCol sm="12" lg="12">
                  <CInput
                    v-uppercase
                    :label="$t('label.secondaryPhone')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    maxlength="15"
                    size="sm"
                    v-model.trim="$v.SecondaryPhone.$model"
                    :is-valid="hasError($v.SecondaryPhone)"
                    :invalid-feedback="errorMessage($v.SecondaryPhone)"
                  />
                </CCol>
                <CCol sm="12">              
                  <CInput
                    :label="$t('label.mainEmail')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    v-model="PrimaryEmail"
                    maxlength="250"
                    size="sm"
                    v-model.trim="$v.PrimaryEmail.$model"
                    :is-valid="hasError($v.PrimaryEmail)"
                    :invalid-feedback="errorMessage($v.PrimaryEmail)"
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    v-model="SecondaryEmail"
                    :label="$t('label.secondaryEmail')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"               
                    maxlength="250"
                    size="sm"
                    v-model.trim="$v.SecondaryEmail.$model"
                    :is-valid="hasError($v.SecondaryEmail)"
                    :invalid-feedback="errorMessage($v.SecondaryEmail)"
                  />
                </CCol>
                <CCol sm="12" v-if="actualizar" >                     
                  <CSelect
                    :label="$t('label.status')"
                    v-model="Status"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    addLabelClasses="required text-right"
                    :value.sync="Status"
                    size="sm"
                    :is-valid="statusSelectColor"
                    :options="selectOptions"
                  />
                </CCol>
              </CRow>
            </Ccol>
          </CRow>
        </CustomTab>
        <CustomTab :title="$t('label.location')" >
          <template #title>
              <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Ubicacion.svg" alt="Card image cap">
              {{$t('label.location')}}                
            </template>
          <CRow class="mt-2">
            
            <CCol sm="12" lg="6">             
              <CInput
                :label="$t('label.latitude')"
                v-uppercase                
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
                v-model.trim="$v.Latitude.$model"
                :is-valid="hasError($v.Latitude)"
                :invalid-feedback="errorMessage($v.Latitude)"
                :placeholder="placeholderPos.lat"
                maxlength="100"
                readonly
                size="sm"
              />
            </CCol>
            <CCol sm="12" lg="6">              
              <CInput
                :label="$t('label.longitude')"
                addLabelClasses="required text-right"
                v-uppercase
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
                v-model.trim="$v.Longitude.$model"
                :is-valid="hasError($v.Longitude)"
                :invalid-feedback="errorMessage($v.Longitude)"
                :placeholder="placeholderPos.lng"
                maxlength="100"
                readonly
                size="sm"
              />
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-3 mb-0 required">{{$t('label.country')}}</label>
                  <div class="col-sm-12 col-lg-9 ">
                    <v-select
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-9'}" 
                      :filter="fuseSearchPais"
                      :options="paisOptions"
                      v-model="pais"
                      label="CountryName"
                      :placeholder="$t('label.select')+$t('label.country')"
                      :class="isPaisValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-3 mb-0 required">{{$t('label.state')}}</label>
                  <div class="col-sm-12 col-lg-9 ">
                    <v-select
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
                      :filter="fuseSearchEstado"
                      :options="estadoOptions"
                      ref="vEstado"
                      label="StateName"
                      v-model="estado"
                      :placeholder="$t('label.select')+$t('label.state')"
                      :disabled="isEstadosEmpty"
                      :class="isEstadoValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-3 mb-0 required">{{$t('label.municipality')}}</label>
                  <div class="col-sm-12 col-lg-9 ">
                    <v-select
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :filter="fuseSearchMunicipios"
                      :options="municipioOptions"
                      label="MunicipalityName"
                      v-model="municipio"
                      :placeholder="$t('label.select')+$t('label.municipality')"
                      :disabled="isMunicipiosEmpty"
                      :class="isMunicipioValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-3 mb-0 required">{{$t('label.parish')}}</label>
                  <div class="col-sm-12 col-lg-9 ">
                    <v-select
                      :filter="fuseSearchParroquias"
                      :options="parroquiaOptions"
                      label="ParishName"
                      addLabelClasses="required"
                      v-model="parroquia"
                      :placeholder="$t('label.select')+$t('label.parish')"
                      :disabled="isParroquiasEmpty"
                      :class="isParroquiaValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">              
              <CTextarea              
                :label="$t('label.address')"
                v-model="FiscalAddress"
                placeholder=""                
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-model.trim="$v.FiscalAddress.$model"
                :is-valid="hasError($v.FiscalAddress)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                addLabelClasses="text-right required"
                size="sm"
              />
            </CCol>
            <CCol sm="12" class="alto mb-2">
              <gmaps-map 
                :options="mapOptions"
                @mounted="ready"
                @click="setMarker"
                @centerChanged="centerChanged"
              >
                <gmaps-marker 
                  :position="position" 
                  :options="markerOptions"
                  @move="handleMarkerPosition"
                />
              </gmaps-map>
            </CCol>
          </CRow>
          <CElementCover :opacity="1" v-show="vLoading" >
            <label class="d-inline">{{$t('label.load')}}... </label>
            <CSpinner size="sm" />
          </CElementCover>
        </CustomTab>        
      </CustomTabs>
      <template #footer>
        <CButton outline color="add" :disabled="isSubmit" @click="evaluaStatus">
          <CIcon name="checkAlt" />&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="limpiarDatos()">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import EmpresaValidations from '@/_validations/empresa/empresaValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { gmapsMap, gmapsMarker } from 'x5-gmaps';
import { isLatitude, isLongitude } from '@/_validations/funciones';
import Fuse from "fuse.js";
import { imgInputMsgs, GenerateImgPlaceholder } from '@/_helpers/funciones';
import PictureInput from '@/components/picture-input';
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';
import MapsMixinCompany from './MapsMixinCompany';

//DATA
function data () {
  return {
    //MODELO
    CompanyId: '',
    CountryId: '',
    StateId: '',
    MunicipalityId: '',
    ParishId: '',
    LetraRif: 'J',
    Latitude: '',
    Longitude: '',
    CompanyObject: '',
    CompanyRif: '',
    CompanyName: '',
    FiscalAddress: '',
    PrimaryEmail: '',
    SecondaryEmail: '',
    PrimaryPhone: '',
    SecondaryPhone: '',
    Status: 1,
    Logo: '/img/errorimage.jpg',
    LogoRoute: '',
    UserId: '',
    //VARIABLES
    ActualizaEmpresa: false,
    image: '/img/uploadPhotoEs.svg',
    Loading: false,    
    paises: [],    
    estados: [],    
    municipios: [],    
    parroquias: [],
    datos: null,
    pais: null,
    estado: null,
    municipio: null,
    parroquia: null,
    vLoading: false,
    LetraOptions: ['J','G','P','V'],
    tituloModal: '',        
    actualizar: false,
    isSubmit: false,
    //IMG
    position: {
      lat: 10.469697379237658, 
      lng: -68.03014330090772
    },
    mapOptions: {
      center: { lat: 10.469697379237658, lng: -68.03014330090772 },
      zoom: 12,
      mapTypeControl: true
    }
  }
}
//METHOD
function limpiarDatos() {
  this.CompanyId='';
  this.pais= null;
  this.estado= null;
  this.municipio= null;
  this.parroquia= null;
  this.CountryId= '';
  this.StateId= '';
  this.MunicipalityId= '';
  this.ParishId= '';
  this.LetraRif= 'J';
  this.Latitude= '';
  this.Longitude= '';
  this.CompanyObject= '';
  this.CompanyRif= '';
  this.CompanyName= '';
  this.FiscalAddress= '';
  this.PrimaryEmail= '';
  this.SecondaryEmail= '';
  this.PrimaryPhone= '';
  this.SecondaryPhone= '';
  this.Status=1;
  this.isSubmit=false;
  this.placeholderPos.lat = 0;
  this.placeholderPos.lng = 0;
  this.ActualizaEmpresa = false;
  this.$nextTick(() => { this.$v.$reset() })
  this.$emit('cerrarModal');
}

function submitFile() {
  this.Loading = true;
  let formData = new FormData();
  formData.append('file1', this.LogoRoute);
  this.$http.ejecutar('POST', 'Company-insert', formData)
  .then((response) => {
    this.LogoRoute = response.data.data.files[0].path;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() =>
    this.Loading = false
  );
}

function evaluaStatus() {
  if (this.Status===0) { 
       this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+' '} ${this.CompanyName}?`,
        }))
      .then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}

function guardar() {
  try {
    this.isSubmit=true;
    this.Loading = true;
    this.$v.$touch();
    
    if (this.$v.$error){
        this.isSubmit=false;
        throw this.$t('label.errorsPleaseCheck');
    }
    let res = [];
    let rif = this.LetraRif+this.CompanyRif;
    let metodo = '';
    let ruta = '';
    let CompanyJson = [];
      //ASIGNACION DE VARIABLES PARA GUARDAR
      if (this.actualizar) {
        CompanyJson = {
          CompanyId: this.CompanyId,
          CompanyName: this.CompanyName,
          CompanyRif: rif,
          CompanyObject: this.CompanyObject,
          CountryId: this.CountryId,
          StateId: this.StateId,
          MunicipalityId: this.MunicipalityId,
          ParishId: this.ParishId,
          Latitude: this.Latitude,
          Longitude: this.Longitude,
          FiscalAddress: this.FiscalAddress,
          PrimaryPhone: this.PrimaryPhone,
          SecondaryPhone: this.SecondaryPhone,
          PrimaryEmail: this.PrimaryEmail,
          SecondaryEmail: this.SecondaryEmail,
          LogoRoute: this.LogoRoute,
          Status: this.Status,
          UserId: this.user.UserId,
        };
        metodo = 'PUT';
        ruta = "Company-update";
      } else {
        CompanyJson = {
          CompanyName: this.CompanyName,
          CompanyRif: rif,
          CompanyObject: this.CompanyObject,
          CountryId: this.CountryId,
          StateId: this.StateId,
          MunicipalityId: this.MunicipalityId,
          ParishId: this.ParishId,
          Latitude: this.Latitude,
          Longitude: this.Longitude,
          FiscalAddress: this.FiscalAddress,
          PrimaryPhone: this.PrimaryPhone,
          SecondaryPhone: this.SecondaryPhone,
          PrimaryEmail: this.PrimaryEmail,
          SecondaryEmail: this.SecondaryEmail,
          LogoRoute: this.LogoRoute,
          UserId: this.user.UserId,
        };
        metodo = 'POST';
        ruta = "Company-insert";
      }
      
      this.$http.ejecutar(metodo, ruta, CompanyJson, { root: 'CompanyJson' })
      .then(response => {
        res = [...response.data.data];
        this.limpiarDatos();
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: "success"
        });
        this.Loading= false;
        this.isSubmit=false;
        this.$emit('child-refresh', true);
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading= false;
        this.isSubmit=false;
      });
  }catch (err) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
    this.isSubmit=false;
  }
}

function handleFileUpload(image) {
  if (image) {
    this.LogoRoute = this.$refs.imageInput.file;
    //this.image = image
    this.submitFile();
  }
}



function centerChanged(center) {
  this.position = center.toJSON();
  this.Latitude = this.position.lat;
  this.Longitude = this.position.lng;
}
//----------------------------LLENADO DE LOS SELECT
function paisOptions() {
  return this.paises.map((pais) => Object.assign({}, pais, {
    label: pais.CountryName,
    value: pais.CountryId
  }));
}

function estadoOptions() {
  return this.estados.map((estado) => Object.assign({}, estado, {
    label: estado.StateName,
    value: estado.StateId
  }));
}

function municipioOptions() {
  return this.municipios.map((municipio) => Object.assign({}, municipio, {
    label: municipio.MunicipalityName,
    value: municipio.MunicipalityId
  }));
}

function parroquiaOptions() {
  return this.parroquias.map((parroquia) => Object.assign({}, parroquia, {
    label: parroquia.ParishName,
    value: parroquia.ParishId
  }));
}
//----------------------------FIN LLENADO DE LOS SELECT
//----------------------------CONSUMO APIS UBICACION
function getPaises() {  
  this.Loading = true;
  this.$http.get("Country-list", { Filter: "ACTIVO" })
  .then((response) => {
    this.paises = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.Loading = false;
  });
}

function getEstados(pais) {
  this.vLoading = true;
  this.$http.get("State-list", { CountryId: pais })
  .then((response) => {
    this.estados = response.data.data;
    this.$refs.vEstado.$el.focus();
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.vLoading = false;
  });
}

function getMunicipios(estado) {
  this.vLoading = true;
  this.$http.get("Municipality-list", { StateId: estado })
  .then((response) => {
    this.municipios = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.vLoading = false;
  });
}

function getParroquias(municipio) {
  this.vLoading = true;
  this.$http.get("Parish-list", { MunicipalityId: municipio })
  .then((response) => {
    this.parroquias = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.vLoading = false;
  });
}
//----------------------------FIN CONSUMO APIS UBICACION
//----------------------------FUSES
function fuseSearchPais(options, search) {
  const fuse = new Fuse(options, {
    keys: ["CodIsoAlpha2", "CodIsoAlpha3", "CountryName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchEstado(options, search) {
  const fuse = new Fuse(options, {
    keys: ["StateName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchMunicipios(options, search) {
  const fuse = new Fuse(options, {
    keys: ["MunicipalityName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchParroquias(options, search) {
  const fuse = new Fuse(options, {
    keys: ["ParishName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}
//----------------------------FIN FUSES
//COMPUTED
function isPaisValid() {
  return this.CountryId == '' ? 'no-valido' : 'valido';
}

function isEstadoValid() {
  return this.StateId == '' ? 'no-valido' : 'valido';
}

function isMunicipioValid() {
  return this.MunicipalityId == '' ? 'no-valido' : 'valido';
}

function isParroquiaValid() {
  return this.ParishId == '' ? 'no-valido' : 'valido';
}

function isEstadosEmpty(){
  return this.estados.length == 0;
}

function isMunicipiosEmpty(){
  return this.municipios.length == 0;
}

function isParroquiasEmpty(){
  return this.parroquias.length == 0;
}

function selectOptions(){
   return[
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ]
}
export default {
  name: 'empresa-modal',
  mixins: [General,ModalMixin,MapsMixinCompany],
  components:{ 
    gmapsMap, 
    gmapsMarker,
    PictureInput,
    CustomTabs,
    CustomTab
  },
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: EmpresaValidations,
  watch: {
    modal: function (val) {
      if (val) {
        this.ActualizaEmpresa = true;
        let _lang = this.$i18n.locale;
        this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        if (this.modal==true) {
          this.actualizar = false;
          this.tituloModal = this.$t('label.nueva')+this.$t('label.company');
        } else {
          let rif                 = this.modal.CompanyRif;
          this.actualizar         = true;
          this.tituloModal        = this.$t('label.edit')+this.$t('label.company')+": "+this.modal.CompanyName;
          this.IdEmpresa          = this.modal.IdEmpresa;
          this.CompanyName        = this.modal.CompanyName;
          this.CompanyRif         = this.modal.CompanyRif;
          this.CompanyObject      = this.modal.CompanyObject;
          this.CoordenadaEmpresa  = this.modal.CoordenadaEmpresa;
          this.Latitude           = this.modal.Latitude;
          this.Longitude          = this.modal.Longitude;
          this.FiscalAddress      = this.modal.FiscalAddress;
          this.PrimaryPhone       = this.modal.PrimaryPhone;
          this.SecondaryPhone     = this.modal.SecondaryPhone;
          this.PrimaryEmail       = this.modal.PrimaryEmail;
          this.SecondaryEmail     = this.modal.SecondaryEmail;
          this.CompanyId          = this.modal.CompanyId;
          this.Status             = this.modal.FgActCompany ? 1 : 0;
          this.CompanyRif         = rif.substr(1, (rif.length - 1));
          this.LetraRif           = rif.substr(0,1);
          this.LogoRoute          = this.modal.Logo;
          this.CountryId          = this.modal.CountryId;
          this.StateId            = this.modal.StateId;
          this.MunicipalityId     = this.modal.MunicipalityId;
          this.ParishId           = this.modal.ParishId;
          this.image              = this.LogoRoute?`${this.$store.getters["connection/getBase"]}${this.LogoRoute.replace('public/', '', 'i')}`:GenerateImgPlaceholder();
          this.datos              = this.modal;
         
          this.pais = {
            CountryId: this.modal.CountryId,
            CountryName: this.modal.CountryName
          }

          this.estado = {
            StateId: this.modal.StateId,
            StateName: this.modal.StateName
          }

          this.municipio = {
            MunicipalityId: this.modal.MunicipalityId,
            MunicipalityName: this.modal.MunicipalityName
          }

          this.parroquia = {
            ParishId: this.modal.ParishId,
            ParishName: this.modal.ParishName
          }
          this.$v.$touch();
        }
        this.getPaises();
      }
    },
    pais: function(newPais) {
      if(newPais){
        if(this.CountryId !== newPais.CountryId) {
          this.estado = null;
          this.municipio = null;
          this.parroquia = null;
          this.municipios = [];
          this.municipios.length = 0;
          this.parroquias = [];
          this.parroquias.length = 0;
          this.StateId = '';
          this.MunicipalityId = '';
          this.ParishId = '';
          this.CountryId = newPais.CountryId
        }
        this.getEstados(newPais.CountryId);
        if(newPais.Latitude && newPais.Longitude){
          this.updatePosition(Number.parseFloat(newPais.Latitude), Number.parseFloat(newPais.Longitude));
          this.handleMarkerPosition({
            lat: Number.parseFloat(newPais.Latitude),
            lng: Number.parseFloat(newPais.Longitude)
          });
        }
      }else{
        this.estados = [];
        this.estados.length = 0;
      }
    },
    latitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.Latitude) && isLongitude(this.Longitude)){
        this.updatePosition(Number.parseFloat(this.Latitude), Number.parseFloat(this.Longitude));
      }
    },
    longitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.Latitude) && isLongitude(this.Longitude)){
        this.updatePosition(Number.parseFloat(this.Latitude), Number.parseFloat(this.Longitude));
      }
    },
    estado: function(newEstado) {
      if(newEstado){
        if(this.StateId !== newEstado.StateId) {
          this.municipio = null;
          this.parroquia = null;
          this.parroquias = [];
          this.parroquias.length = 0;
          this.MunicipalityId = '';
          this.ParishId = '';
          this.StateId = newEstado.StateId
        }
        this.getMunicipios(newEstado.StateId);
      }else{
        this.municipios = [];
        this.municipios.length = 0;
      }
    },
    municipio: function(newMunicipio) {
      if(newMunicipio){
        if(this.MunicipalityId !== newMunicipio.MunicipalityId) {
          this.parroquia = null;
          this.ParishId = '';
          this.MunicipalityId = newMunicipio.MunicipalityId;
        }
        this.getParroquias(newMunicipio.MunicipalityId);
      }else{
        this.parroquias = [];
        this.parroquias.length = 0;
      }
    },
    parroquia: function(newParroquia) {
      if (newParroquia) this.ParishId = newParroquia.ParishId
    }
  },
  methods: {
    centerChanged,
    getPaises,
    fuseSearchPais,
    getEstados,
    fuseSearchEstado,
    getMunicipios,
    fuseSearchMunicipios,
    getParroquias,
    fuseSearchParroquias,
    limpiarDatos,
    evaluaStatus,
    guardar,
    submitFile,
    handleFileUpload,
  },
  computed: {
    isPaisValid,
    isEstadoValid,
    paisOptions,
    selectOptions,
    isEstadosEmpty,
    estadoOptions,
    isMunicipiosEmpty,
    municipioOptions,
    isMunicipioValid,
    isParroquiasEmpty,
    parroquiaOptions,
    isParroquiaValid,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style scoped>
.alto {
  min-height: 200px !important;
  max-height: 200px !important;
}
</style>

<style lang="scss">

.text-table .custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>